<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Logs</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Logs</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getLogsList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo Username</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập user name"
                      v-model="username"
                    />
                  </div>
                </form>
                <form @submit.prevent="getLogsList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo Object Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập object id"
                      v-model="object_id"
                    />
                  </div>
                </form>
                <form class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getLogsList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách Logs'"
            :columns="columns"
            :rows="manipulatedLogs"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center">
              <button class="btn btn-success font-10 py-1 btn-xs">Action</button>
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import logsApi from "@/api/logs";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery, formatNumber } from "@/helpers/index";

export default {
  name: "Logs",
  components: {
    Datatable,
    LoadingVue,
  },
  data() {
    return {
      username: null,
      object_id: null,
      limit: 50,
      logs: [],
      loading: false,
      columns: [
        { label: "Username", field: "username" },
        { label: "Description", field: "description" },
        { label: "User Id", field: "user_id" },
        { label: "Object Id", field: "object_id", fb_link: true },
        { label: "Tool name", field: "tool_name" },
        { label: "Package name", field: "package_name" },
        { label: "Tool_id", field: "tool_id" },
        { label: "Prices Id", field: "prices_id" },
        { label: "Số lượng", field: "quantity", numeric: true },
        { label: "Action", field: "action" },
        { label: "Coin", field: "coin_html", html: true },
        { label: "Status", field: "status_name" },
        { label: "Note", field: "note" },
        { label: "Ngày tạo", field: "created_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedLogs() {
      return this.logs.map((log) => {
        const coinDiff = Math.abs(log.new_coin - log.old_coin);
        log.status_name = log.status === 0 ? "Thất bại" : log.status === 1 ? "Thành công" : "";
        let oldCoin = `<button class='btn btn-xs py-0 btn-primary'>${formatNumber(
          log.old_coin
        )}</button>`;
        let coin = `<button class='btn btn-xs py-0 btn-success'>${formatNumber(coinDiff)}</button>`;
        let newCoin = `<button class='btn btn-xs py-0 btn-danger'>${formatNumber(
          log.new_coin
        )}</button>`;
        var coinResult;
        if (log.old_coin > log.new_coin) {
          coinResult = oldCoin + " - " + coin + " = " + newCoin;
        } else {
          coinResult = oldCoin + " + " + coin + " = " + newCoin;
        }
        log.coin_html = coinResult;
        return log;
      });
    },
  },
  created() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      const params = {
        limit: this.limit,
        username: this.username,
        object_id: this.object_id,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await logsApi.list(queries);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.logs = response.data;
        } else {
          this.$toastr.error(response.message);
        }
      } else {
        this.$toastr.error("Tải không thành công !");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
